import React from 'react'
import Navbar1 from '../components/Navbar1'
import About from '../components/About'
import Footer from '../components/Footer'
import Gallery from "../components/Gallery"
import Title from "../components/Title"
import InTouch from '../components/InTouch'
import AdditionalNavbar from '../components/AdditionalNavbar'

function AboutPage() {
  return (
    <div>
       <AdditionalNavbar/>
        <Navbar1/>
        <Title heading={"About Us"}/>
        <About/>
        <Gallery/>
        <InTouch/>
        <Footer/>
    </div>
  )
}

export default AboutPage