import React from 'react'
import Navbar1 from '../components/Navbar1'
import Hero from '../components/Hero'
import About from '../components/About'
import Services from '../components/Services'
import Gallery from '../components/Gallery'
import Footer from '../components/Footer'
import WhyChooseUs from '../components/WhyChooseUs'
// import ContactUs from '../components/ContactUs'
import InTouch from '../components/InTouch'
import AdditionalNavbar from '../components/AdditionalNavbar'
function Home() {
  return (
    <div>
      <AdditionalNavbar/>
        <Navbar1/>
        <Hero/>
        <About/>
        <WhyChooseUs/>
        <Services/>
        <Gallery/>
        <InTouch/>
        {/* <ContactUs/> */}
        <Footer/>
       
    </div>
  )
}

export default Home