import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    msg: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappNumber = "+918943997999"; // Update with your number
    const { name, email, mobile, service, msg } = formData;
    if (!name || !mobile) {
      alert("Please fill in all required fields.");
      return;
    }

    const whatsappMessage = `Hello, I would like to book an appointment.\n\nName: ${name}\nEmail: ${email}\nMobile: ${mobile}\nService: ${service}\nMessage: ${msg}`;

    window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      whatsappMessage
    )}`;
  };

  const handleClear = () => {
    setFormData({ name: "", email: "", mobile: "", service: "", msg: "" });
  };

  const contacts = [
    {
      logo: <FaPhone size={35} />,
      name: "Have any question?",
      value: "+91 8943997999",
    },
    {
      logo: <FaEnvelope size={35} />,
      name: "Write email",
      value: "baijuspark@gmail.com",
    },
    {
      logo: <FaMapMarkerAlt size={35} />,
      name: "Visit anytime",
      value: "Mavoor Rd, Kottooli, Kozhikode, 673016",
    },
  ];

  return (
    <Box sx={{ flex: 1, backgroundColor: "#28282B" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          color: "#fff",
        }}
      >
        {/* Send Us Email Section */}
        <Box sx={{ flex: 1, p: { xs: 2, md: 5 } }}>
          <Typography color="#8A6854">Send us email</Typography>
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: "2rem", md: "3rem" }, mt: 1 }}
          >
            Feel free to write
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              mt: 3,
            }}
          >
            <TextField
              label="Name"
              name="name" // Add name attribute
              variant="filled"
              fullWidth
              sx={{ mt: 2, borderBottom: "none", backgroundColor: "#fff" }}
              value={formData.name}
              onChange={handleChange}
              required
            />

            <TextField
              label="Email"
              name="email" // Add name attribute
              variant="filled"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              sx={{
                mt: 2,
                ml: { xs: 0, md: 2 },
                borderBottom: "none",
                backgroundColor: "#fff",
              }}
              required
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
            }}
          >
            <FormControl fullWidth variant="filled" sx={{ mt: 2 }}>
              <InputLabel>Service</InputLabel>
              <Select
                name="service" // Add name attribute
                value={formData.service}
                onChange={handleChange}
                sx={{ backgroundColor: "#fff" }}
              >
                 <MenuItem value="Hair Studiog">Hair Studio</MenuItem> 
                <MenuItem value="Beauty Studio">Beauty Studio</MenuItem>
                <MenuItem value="Manicure & Pedicure">Manicure & Pedicure</MenuItem>
                <MenuItem value="Bridal Studio">Bridal Studio</MenuItem>
                <MenuItem value="Spa">Spa</MenuItem>
                <MenuItem value="Tattoo Studio">Tattoo Studio</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Phone"
              name="mobile" // Add name attribute
              variant="filled"
              fullWidth
              value={formData.mobile}
              onChange={handleChange}
              sx={{
                mt: 2,
                ml: { xs: 0, md: 2 },
                borderBottom: "none",
                backgroundColor: "#fff",
              }}
              required
            />
          </Box>
          <TextField
            label="Message"
            name="msg" // Add name attribute
            variant="filled"
            fullWidth
            multiline
            rows={4}
            value={formData.msg}
            onChange={handleChange}
            sx={{ mt: 2, borderBottom: "none", backgroundColor: "#fff" }}
            required
          />

          <Box sx={{ display: "flex", mt: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#381010",
                mr: 5,
                "&:hover": { backgroundColor: "#fff", color: "#381010" },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#381010",
                mr: 5,
                "&:hover": { backgroundColor: "#fff", color: "#381010" },
              }}
              size="large"
              onClick={handleClear}
            >
              Clear Form
            </Button>
          </Box>
        </Box>

        {/* Contact Info Section */}
        <Box sx={{ flex: 1, p: { xs: 2, md: 5 } }}>
          <Typography color={"#8A6854"}>Need any help?</Typography>
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: "2rem", md: "3rem" }, mb: { xs: 1, md: 2 } }}
          >
            Get in touch with us
          </Typography>
          <Typography mb={5} color="#626064">
            We're here to assist with any inquiries or concerns. Whether you
            have questions about our services or need help, feel free to reach
            out. Our team is ready to help with all your needs.
          </Typography>

          {contacts.map((contact, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box
                width={{ xs: "75px", md: "75px" }}
                height={{ xs: "75px", md: "75px" }}
                sx={{
                  backgroundColor: "#381010",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#381010",
                  },
                }}
              >
                {contact.logo}
              </Box>
              <Box ml={2}>
                <Typography fontWeight={"bold"} color={"#8A6854"}>
                  {contact.name}
                </Typography>
                <Typography mt={1}>{contact.value}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: "400px", md: "450px", lg: "500px" },
          mt: 5,
          overflow: "hidden",
          boxShadow: 2,
        }}
      >
        <iframe
          title="Map"
          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d9648.844341265009!2d75.79703573535073!3d11.261891128174073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3ba6591f7b308d2d%3A0x1dcacf6134ca2a77!2sMavoor%20Rd%2C%20Kottooli%2C%20Kozhikode%2C%20Kerala%20673016!3m2!1d11.2591944!2d75.806133!5e0!3m2!1sen!2sin!4v1689685774884!5m2!1sen!2sin"
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </Box>
    </Box>
  );
}

export default ContactUs;
