import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Container,
  useMediaQuery,
  Link,
  IconButton,
  Fab
} from "@mui/material";
import {
  FaArrowRight,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegClock,
  FaEnvelope,
} from "react-icons/fa";
import { FaInstagram, FaFacebook, FaXTwitter } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa";


const Footer = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [showScrollBtn, setShowScrollBtn] = useState(false);
  // Effect to show or hide the scroll button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollBtn(true);
      } else {
        setShowScrollBtn(false);
      }
    };
   
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };
  const links=[
    {name:"Home",src:"/"},
    {name:"About",src:"about"},
    {name:"Services",src:"services"},
    {name:"Gallery",src:"gallery"},
    {name:"Contact",src:"contact"},
    {name:"Privacy Policy",src:"privacy-policy"},

  ]
  return (
    <Box
      sx={{
        backgroundColor: "#131819",
        color: "white",
        position: "relative",
        paddingTop: 1,
        
        
      }}
    >
      <Container>
        {/* Centered Box with Border */}
        <Box
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          alignItems="center"
          mb={10}
          p={3}
          sx={{
            border: "1px solid #7D7070",
            borderRadius: "8px",
            maxWidth: isSmallScreen ? "100%" : "1000px",
            margin: "40px auto 0 auto",
            marginTop: 5,
            
          }}
        >
          {/* Title */}
          <Typography
            variant="h6"
            component="a"
            href="#"
            sx={{
              color: "white",
              textDecoration: "none",
              marginBottom: 2,
              textAlign: "center",
              mr: 3,
            }}
          >
            Spark International Beauty Salon & Spa
          </Typography>

          {/* Subscription Form */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ width: "100%", maxWidth: "550px" }}
          >
            <TextField
              label="Enter Your Email"
              type="email"
              variant="outlined"
              fullWidth
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                mb: isSmallScreen ? 2 : 0, // Margin for small screens
              }}
              InputProps={{
                endAdornment: !isSmallScreen && (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<FaArrowRight />}
                      sx={{
                        backgroundColor: "#381010",
                        color: "white",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        height: "100%",
                        px: 2,
                        
                        "&:hover": {
                          color: "#381010",
                          backgroundColor:"#fff"
                        },
                      }}
                    >
                      Subscribe Now
                    </Button>
                  </InputAdornment>
                ),
              }}
            />

            {/* Button below input on small screens */}
            {isSmallScreen && (
              <Button
                variant="contained"
                color="primary"
                endIcon={<FaArrowRight />}
                size="large"
                sx={{
                  backgroundColor: "#D5A153",
                  color: "white",
                  width: "100%", // Full width on mobile
                  mt: 1, // Add margin for mobile
                  "&:hover": {
                    backgroundColor: "#c09448",
                  },
                }}
              >
                Subscribe Now
              </Button>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent={isSmallScreen ? "center" : "space-around"} // Use 'space-around' for even spacing
          flexDirection={isSmallScreen ? "column" : "row"} // Stack vertically on small screens
          marginTop={4}
          p={isSmallScreen ? 0 : 3} // Add padding to the sides for larger screens
          
           
        >
          {/* Quick Links Section */}
          <Box
            flex={1}
            mb={isSmallScreen ? 2 : 0}
            pr={isSmallScreen ? 0 : 2}
           
          >
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Box>
              {links.map((quickLink,index)=>(
                <>
                 <Link href={quickLink.src} color="inherit" underline="hover" key={index}>
                 {quickLink.name}
                 </Link>
                 <br/>
                 </>
              ))}
            </Box>
          </Box>

          {/* Services Section */}
          <Box
            flex={1}
            mb={isSmallScreen ? 2 : 0}
            pr={isSmallScreen ? 0 : 2}
            // textAlign={isSmallScreen ? "center" : "left"}
          >
            <Typography variant="h6" gutterBottom>
              Services
            </Typography>
            <Box>
              <Link href="#" color="inherit" underline="hover">
                Hair Cutting
              </Link>
              <br />
              <Link href="#" color="inherit" underline="hover">
                Shaving & Design
              </Link>
              <br />
              <Link href="#" color="inherit" underline="hover">
                Hair Colors
              </Link>
              <br />
              <Link href="#" color="inherit" underline="hover">
                Beauty & Spa
              </Link>
              <br />
              <Link href="#" color="inherit" underline="hover">
                Body Massages
              </Link>
            </Box>
          </Box>

          {/* Contact Us Section */}
          <Box
            flex={1}
            mb={isSmallScreen ? 2 : 0}
            pr={isSmallScreen ? 0 : 3}
            textAlign={isSmallScreen ? "left" : "left"}
          >
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                mb={1}
                // justifyContent={isSmallScreen ? "center" : "flex-start"}
                textAlign={isSmallScreen ? "left" : "left"}
              >
                <FaMapMarkerAlt style={{ marginRight: 8 }} />
                <Typography variant="body2">
                Mavoor Rd, Kottooli, Kozhikode, 673016

                </Typography>
              </Box>
              <Box
                
              >
                <FaPhoneAlt style={{ marginRight: 8 }} />
                <Link href="tel:+91 8943997999" color="inherit">
                +91 8943997999
                </Link>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                mb={1}
                // justifyContent={isSmallScreen ? "center" : "flex-start"}
              >
                <FaRegClock style={{ marginRight: 8 }} />
                <Typography variant="body2">
                  Sun - Friday, 08 am - 09 pm
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                // justifyContent={isSmallScreen ? "center" : "flex-start"}
              >
                <FaEnvelope style={{ marginRight: 8 }} />
                <Typography sx={{textDecoration:"underline"}} color="inherit">
                baijuspark@gmail.com
                </Typography>
              </Box>
            </Box>
        {/*  */}
          <Box mt={1}>
            <IconButton
              sx={{
                color: 'white',
                bgcolor: '#381010',
                '&:hover': { bgcolor: 'white', color:"#381010" },
                mx: 1,
              }}
              href='https://www.facebook.com/profile.php?id=100089770780722'
            >
              <FaFacebook size="1em" />
            </IconButton>
            <IconButton
              sx={{
                color: 'white', 
                bgcolor: '#381010',
                '&:hover': { bgcolor: 'white', color:"#381010" },
                mx: 1,
              }}
              
               href='https://www.instagram.com/spark_international_salon/'
            >
              <FaInstagram size="1em" />
            </IconButton>
            <IconButton
              sx={{
                color: 'white',
                bgcolor: '#381010',
                '&:hover': { bgcolor: 'white', color:"#381010" },
                mx: 1,
              }}
            >
              <FaXTwitter size="1em" />
            </IconButton>
          </Box>
          </Box>
        </Box>
      </Container>
       {/* Scroll to Top Button */}
       {showScrollBtn && (
        <Fab
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            bgcolor: "#D5A153",
            color: "white",
            "&:hover": {
              bgcolor: "#c09448",
            },
          }}
        >
          <FaChevronUp />
        </Fab>
      )}

      <Box
        py={2}
        textAlign="center"
        bgcolor="#28282B"
        mt={1}
        // color="#fff"
      >
        <Typography variant="body2">
          &copy; 2024 Spark International Beauty Salon & Spa. All Rights
          Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
