import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  // Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Typography,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemText,
  Modal,
} from "@mui/material";
import { FiMenu, FiSearch } from "react-icons/fi";
import { FaInstagram, FaFacebook, FaWhatsapp } from "react-icons/fa6";
import logo from "../assets/image.png";
import { Link, useNavigate } from "react-router-dom";
import { serviceList1 } from "../data/service";
import { motion, useAnimation, useInView, 
  // useScroll, useTransform 
} from "framer-motion";
function Navbar1() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const whatsappNumber = "+918943997999";
  // 
  const gridContainerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("show");
    }
  }, [isInView, mainControls]);

  // 

  const socialIcons = [
    {
      icon: FaFacebook,
      href: "https://www.facebook.com/profile.php?id=100089770780722",
    },
    {
      icon: FaInstagram,
      href: "https://www.instagram.com/spark_international_salon/",
    },
    {
      icon: FaWhatsapp,
      href: `https://api.whatsapp.com/send?phone=${whatsappNumber}`,
    },
  ];

  const navItems = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/about" },
    { label: "Services", path: "/services" },
    { label: "Gallery", path: "/gallery" },
    { label: "Contact", path: "/contact" },
  ];

  const handleInputChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchItem(searchTerm);

    if (searchTerm.trim() === "") {
      setSuggestions([]);
      return;
    }

    const filteredItems = serviceList1.flatMap((service) => {
      const matchingHeadings = service.heading
        .toLowerCase()
        .includes(searchTerm)
        ? [{ label: service.heading.substring(0, 70), service }]
        : [];

      const matchingSubServices =
        service.largeDescription.subServices
          ?.filter((subService) =>
            subService.toLowerCase().includes(searchTerm)
          )
          .map((subService) => ({
            label: subService.substring(0, 40),
            service,
          })) || [];

      return [...matchingHeadings, ...matchingSubServices];
    });

    setSuggestions(filteredItems);
  };

  const handleSuggestionClick = (service) => {
    setSearchItem("");
    setSuggestions([]);
    const formattedId = service.heading.toLowerCase().replace(/\s+/g, "-");
    navigate(`/service/${formattedId}`, {
      state: { service },
    });
  };

  return (
    <>
     <motion.section
        ref={containerRef}
        variants={gridContainerVariants}
        initial="hidden"
        animate={mainControls}
        style={{backgroundColor:"#000"}}
      >
      <AppBar
        position="sticky"
        sx={{ backgroundColor: "#000", boxShadow: "none" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: { xs: "0 10px", sm: "0 32px", md: "0 32px", lg: "0 32px" },
            ml: { xs: "3px", md: 0 },
            mt: "10px",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setMenuOpen(!menuOpen)}
            sx={{ display: { xs: "block", lg: "none" }, color: "#fff" }}
          >
            <FiMenu size={30} />
          </IconButton>

          <motion.div variants={textVariants}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: "60px",
              ml: { xs: 0, lg: 15 },
            }}
          >

            {navItems.slice(0, 3).map((item) => (
              <Link
                key={item.label}
                to={item.path}
                style={{
                  cursor: "pointer",
                  color: "#fff",
                  fontSize: "17px",
                  fontFamily: "poppins",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
              >
                {item.label}
              </Link>
            ))}
           
          </Box>
          </motion.div>
          {/* Title */}

          <Box
            component={Link}
            to="/"
            sx={{
              zIndex: 99,
              alignItems: "center",
              textDecoration: "none",
              justifyContent: "center",
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              ml: { xs: 0, md: 10 },
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="logo"
              sx={{
                width: { xs: "60px", sm: "60px", md: "75px" },
                height: "auto",
              }}
            />
            <Typography
              sx={{
                m: 1,
                color: "#fff",
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: { xs: "15px", md: "22px" },
                textAlign: "center",
              }}
            >
              Byju Spark International <br /> Beauty Salon & Spa
            </Typography>
          </Box>
            <motion.div variants={textVariants}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: "40px",
              ml: { xs: 0, lg: 5 },
            }}
          >
            {navItems.slice(3, 6).map((item) => (
              <Link
                key={item.label}
                to={item.path}
                style={{
                  cursor: "pointer",
                  color: "#fff",
                  fontSize: "17px",
                  fontFamily: "poppins",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
              >
                {item.label}
              </Link>
            ))}
          </Box>
          </motion.div>
          {/* Search Bar */}
          <motion.div variants={textVariants}>
          <Box
            sx={{
              flexDirection: "column",
              position: "relative",
              display: { xs: "none", lg: "flex" },
              borderColor: "black",
              marginRight: 10,
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search Here"
              value={searchItem}
              onChange={handleInputChange}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black",
                  },
                  "&:hover fieldset": {
                    borderColor: "black",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                },
              }}
            />
            {suggestions.length > 0 && (
              <List
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  maxHeight: "200px",
                  overflowY: "auto",
                  backgroundColor: "#fff",
                  zIndex: 1,
                  boxShadow: 2,
                  borderRadius: 1,
                  color: "black",
                }}
              >
                {suggestions.map((suggestion, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion.service)}
                  >
                    <ListItemText primary={suggestion.label} />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          </motion.div>
          <IconButton
            sx={{ display: { xs: "block", lg: "none" }, color: "#fff" }}
            onClick={() => setSearchModalOpen(true)}
          >
            <FiSearch size={24} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Modal
        open={searchModalOpen}
        onClose={() => setSearchModalOpen(false)}
        sx={{ display: "flex", mb: 2, width: "100%" }}
      >
        <Box
          sx={{
            backgroundColor: "#28282B",

            width: "100%",
            maxWidth: "400px",
            height: "100px",
            borderRadius: 2,
            padding: 3,
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search Here"
            value={searchItem}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
                "& .MuiInputBase-input": {
                  color: "white", // Set input text color to white
                },
              },
              "& .MuiInputBase-input::placeholder": {
                color: "white", // Customize your placeholder color
                fontSize: "14px", // Customize font size
                fontStyle: "italic", // Customize style
              },
            }}
          />
          {suggestions.length > 0 && (
            <List
              sx={{
                maxHeight: "200px",
                overflowY: "auto",
                backgroundColor: "#fff",
                zIndex: 1,
                boxShadow: 2,
                borderRadius: 1,
                color: "black",
              }}
            >
              {suggestions.map((suggestion, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion.service)}
                >
                  <ListItemText primary={suggestion.label} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Modal>

      <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <Box
          sx={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "16px",
            backgroundColor: "#000",
            height: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box
              component="img"
              src={logo}
              alt="logo"
              sx={{
                width: "40px",
                height: "auto",
              }}
            />
            <Typography
              sx={{
                ml: 1,
                color: "#fff",
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: { xs: "13px", lg: "18px" },
              }}
            >
              Byju Spark International <br /> Beauty Salon & Spa
            </Typography>
            <Divider />
          </Box>

          {navItems.map((item) => (
            <MenuItem
              key={item.label}
              component={Link}
              to={item.path}
              onClick={() => setMenuOpen(false)}
              sx={{ color: "#fff", fontFamily: "Quicksand" }}
            >
              {item.label}
            </MenuItem>
          ))}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              borderColor: "black",
              mb: 2,
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search Here"
              value={searchItem}
              onChange={handleInputChange}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black",
                  },
                  "&:hover fieldset": {
                    borderColor: "black",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                },
              }}
            />
            {suggestions.length > 0 && (
              <List
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  maxHeight: "200px",
                  overflowY: "auto",
                  backgroundColor: "#fff",
                  zIndex: 1,
                  boxShadow: 2,
                  borderRadius: 1,
                  color: "black",
                }}
              >
                {suggestions.map((suggestion, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion.service)}
                  >
                    <ListItemText primary={suggestion.label} />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          {/* <Button
            variant="contained"
            component={Link}
            to={"/book"}
            size="small" // Reduce the size from medium to small
            sx={{
              width: "150px",
              backgroundColor: "#381010",
              color: "#fff",
              fontFamily: "Quicksand",
              fontSize: "10px", // Reduce the font size
              height: "40px", // Reduce the button height
              borderTopLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              px: "20px", // Reduce horizontal padding
              py: "6px", // Reduce vertical padding
              "&:hover": {
                backgroundColor: "#fff",
                color: "#381010",
                boxShadow: 2,
              },
            }}
          >
            Book Now
          </Button> */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            {socialIcons.map(({ icon: Icon, href }, idx) => (
              <IconButton
                key={idx}
                sx={{
                  color: "white",
                  bgcolor: "#381010",
                  "&:hover": { bgcolor: "white", color: "#381010" },
                  mx: 1,
                }}
                href={href}
              >
                <Icon size="1em" />
              </IconButton>
            ))}
          </Box>
        </Box>
      </Drawer>
      </motion.section>
    </>
  );
}

export default Navbar1;
