import React from 'react'
import Navbar1 from '../components/Navbar1'
import Services from '../components/Services'
import Footer from '../components/Footer'
import Title from "../components/Title"
import { Box } from '@mui/material'
import AdditionalNavbar from '../components/AdditionalNavbar'

function ServicePage() {
  return (
    <Box sx={{backgroundColor:"#28282B"}}>
      <AdditionalNavbar/>
        <Navbar1/>
        <Title heading={"Services"}/>
        <Services/>
        <Box mt={10}></Box>
        <Footer/>
    </Box>
  )
}

export default ServicePage