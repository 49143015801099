import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
// import WhyChooseUs from "./components/WhyChooseUs";
// import Book from "./pages/Book";
import ServiceDetails from "./pages/ServiceDetails";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import Gallerypage from "./pages/Gallerypage";
import ContactPage from "./pages/ContactPage";
import ScrollToTop from "./components/ScrollToTop";
import { Helmet } from "react-helmet-async";
import AppointmentPage from "./pages/AppointmentPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <div>
      <Helmet>
        {/* Title for SEO */}
        <title>Byju Spark International Salon & Beauty Mall, Calicut</title>

        {/* Meta description for SEO */}
        <meta
          name="description"
          content="Spark Salon & Beauty Mall in Calicut offers premium Hair, Nail, Beauty, Bridal, Spa, and Tattoo services, ensuring luxury, style, and relaxation in every visit.
"
        />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.byjuspark.com/" />

        {/* Open Graph Tags for Social Media */}
        <meta
          property="og:title"
          content="Byju Spark International Salon & Beauty Mall, Calicut"
        />
        <meta
          property="og:description"
          content="Find the top beauty parlour and salon services at Byju Spark International Salon & Beauty Mall, located in Calicut. Experience premium care at our Hair Studio, Nail Studio, Beauty Studio, Bridal Studio, Spa center, and Tattoo Studio."
        />

        <meta property="og:url" content="https://www.byjuspark.com/" />
        <meta property="og:type" content="website" />

        {/* Optional: Add image for social sharing (replace with actual image URL) */}
        <meta
          property="og:image"
          content="https://www.byjuspark.com/images/social-share.jpg"
        />
      </Helmet>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/service/:heading" element={<ServiceDetails />} />
        {/* Uncomment the following lines to enable additional routes */}
        <Route path="/gallery" element={<Gallerypage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/book" element={<AppointmentPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
