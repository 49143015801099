import React, { useEffect, useRef } from "react";
import background from "../assets/beauty-spa.avif";
import { Box, Typography,Breadcrumbs,  } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from "framer-motion";

function Title({heading}) {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true });
  const mainControls = useAnimation();
 

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  const imageVariants = {
    hidden: { opacity: 0, scale: 1.2 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeOut", delay: 0.5 },
    },
  };


  return (
    <> <motion.section ref={containerRef} initial="hidden" animate={mainControls} style={{backgroundColor:"#343434"}}>
      <motion.div variants={imageVariants}>
    <div> {/* Header Section */}
    <Box
      sx={{
        position: "relative", // Make the Box relative to its content for overlay positioning
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#50504F",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${background})`,
        height: { xs: 200, lg: 400 },
        p: 2,
        textAlign: "center",
        color: "#0E384C",
        // Ensure content is above the overlay
        zIndex: 1,
      }}
    >
     
      {/* Black overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Black with 50% opacity
          zIndex: 0, // Ensures the overlay is below the content
        }}
      />

      {/* Content Box */}
      <Typography
        component="h1"
        sx={{
          color: "#fff", // Make the text color white for better contrast
          fontSize: { xs: "2rem", lg: "3rem" },
          fontWeight: "bold",
          mt: 3,
          zIndex: 1, // Ensure the text is above the overlay
        }}
      >
        {heading}
      </Typography>

      <Breadcrumbs aria-label="breadcrumb" sx={{ zIndex: 1 , color:"white"}}>
        <Link to="/" style={{ color: "#fff" }}>
          <Typography variant="h6">Home</Typography>
        </Link>
        <Typography variant="h6" style={{ color: "#fff" }}>
          {heading}
        </Typography>
      </Breadcrumbs>
    
    </Box>
</div>
</motion.div>
</motion.section>
</>
  )
}

export default Title