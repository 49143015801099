import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Button, Modal, IconButton } from "@mui/material";
import { PageFlip } from "page-flip";
import { FaPlay, FaTimes } from "react-icons/fa";
import Marquee from "react-fast-marquee";
import ReactPlayer from "react-player";
import backGround from "../assets/background.avif";
import { Link } from "react-router-dom";
import image1 from "../assets/book/0.jpg";
import image2 from "../assets/book/1.jpg";
import image3 from "../assets/book/2.jpg";
import image4 from "../assets/book/3.jpg";
import image5 from "../assets/book/5.png";
import image6 from "../assets/book/6.png";
import image7 from "../assets/book/7.png";
import image8 from "../assets/book/8.png";
import image9 from "../assets/book/9.png";
import image10 from "../assets/book/10.png";
import image11 from "../assets/book/11.png";
import image12 from "../assets/book/12.png";
import image13 from "../assets/book/13.png";
import image14 from "../assets/book/14.png";
import image15 from "../assets/book/15.png";
import image16 from "../assets/book/16.png";
import image17 from "../assets/book/17.png";
import image18 from "../assets/book/18.png";
import image19 from "../assets/book/19.png";
import image20 from "../assets/book/20.png";
import image21 from "../assets/book/21.png";
import image22 from "../assets/book/22.png";
import image23 from "../assets/book/23.png";
import image24 from "../assets/book/24.png";
import image25 from "../assets/book/25.png";
import image26 from "../assets/book/26.png";
import image27 from "../assets/book/27.png";
import image28 from "../assets/book/28.png";
import image29 from "../assets/book/29.png";
import image30 from "../assets/book/30.png";
import image31 from "../assets/book/31.png";
import image32 from "../assets/book/32.png";
import image33 from "../assets/book/33.png";
import image34 from "../assets/book/34.png";
import image35 from "../assets/book/35.png";
import { motion, useAnimation, useInView } from "framer-motion";

function WhyChooseUs() {
  const pageFlipRef = useRef(null);
  const [openVideo, setOpenVideo] = useState(false); // For video modal state

  const handleOpenVideo = () => setOpenVideo(true);
  const handleCloseVideo = () => setOpenVideo(false);
  // Animation
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true });
  const mainControls = useAnimation();
 

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  const imageVariants = {
    hidden: { opacity: 0, scale: 1.2 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeOut", delay: 0.5 },
    },
  };


  // 

  useEffect(() => {
    const bookElement = document.getElementById("book");
    if (!bookElement) return;
  
    const pageFlip = new PageFlip(bookElement, {
      // width: window.innerWidth <= 400 ? 800 : 100,  // Increase width for mobile screens
      // height: window.innerWidth <= 600 ? 1000 : 300, // Increase height for mobile screens
      width: 400, // required parameter - base page width
      height: 600, // required parameter - base page height
      size: "stretch",
      flippingTime: 2000,
      mobileScrollSupport: false,
      backgroundColor: "#28282B"
      
    });
  
    pageFlipRef.current = pageFlip;
  
    // Load your images into the book
    pageFlip.loadFromImages([
      image1, image2, image3, image4, image5, image6, image7, image8, 
      image9, image10, image11, image12, image13, image14, image15, 
      image16, image17, image18, image19, image20, image21, image22, 
      image23, image24, image25, image26, image27, image28, image29, 
      image30, image31, image32, image33, image34, image35, // Add last cover page
    ]);
  
    const flipPages = () => {
      const pageCount = pageFlip.getPageCount(); // Total pages
      const currentPage = pageFlip.getCurrentPageIndex(); // Current page index
  
      // Check if we reached the last page
      if (currentPage < pageCount - 1) {
        pageFlip.flipNext(); // Flip to the next page
      } else {
        pageFlip.flip(0); // Restart from the first page
      }
    };
  
    // Flip the pages every 500ms
    const intervalId = setInterval(flipPages, 2500);
  
    // Cleanup: clear interval and destroy PageFlip instance when the component unmounts
    return () => {
      clearInterval(intervalId);
      if (pageFlipRef.current) {
        pageFlipRef.current.destroy();
      }
    };
  }, []);
  
  
  

  // const videoOptions = {
  //   height: "390",
  //   width: "640",
  //   playerVars: {
  //     autoplay: 1,
  //   },
  // };

  return (
    <> <motion.section ref={containerRef} initial="hidden" animate={mainControls} style={{backgroundColor:"#343434"}}>
    <Box backgroundColor={"#28282B"}>
      <motion.image variants={imageVariants}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: { xs: 0, md: 10 },
          color: "#381010",
          backgroundImage: `url(${backGround})`,
         
          backgroundSize: "cover",
          backgroundPosition: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box textAlign={{ xs: "center", md: "left" }}>
          <motion.div variants={textVariants}>
          <Typography
          variant="h2"
            sx={{
              fontSize: { xs: "32px", md: "50px" },
              fontWeight: "bold",
              lineHeight: 1.2,
              mb: { xs: 3, md: 0 },
              mt: { xs: 2, md: 0 }
            }}
          >
            Book & feel our Incredible
          </Typography>
         
         
          <Typography
          variant="h2"
            sx={{
              fontSize: { xs: "32px", md: "50px" },
              fontWeight: "bold",
              lineHeight: 1.5,
              mb: 3,
            }}
          >
            Spa Experience
          </Typography>
         
          <Button
            variant="contained"
            component={Link}
            to={'/book'}
            sx={{
              backgroundColor: "#381010",
              color: "#fff",
              fontSize: "16px",
              height: "60px",
              borderTopLeftRadius: "30px",
              borderBottomRightRadius: "30px",
              px: "26px",
              py: "8px",
              "&:hover": {
                backgroundColor: "#fff",
                color:"#381010",
                boxShadow: 2,
              },
            }}
          >
            Make Appointment
          </Button>
          </motion.div>
        </Box>
        <motion.div variants={textVariants}>
        <Box
          sx={{ display: "flex", alignItems: "center", mt: { xs: 2, md: 0 },mb:{xs: 2, md: 0 } }}
        >
          
          <Typography variant="h6" sx={{ mr: 2, }}>
            Watch Now
          </Typography>
         
          <Button
            variant="contained"
            onClick={handleOpenVideo}
            sx={{
              backgroundColor: "#DDDAD2",
              borderRadius: "50%",
              minWidth: "50px",
              color:"#381010",
              minHeight: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px dotted #2E4630",
              "&:hover": {
                backgroundColor: "#381010",
                color:"white"
              },
            }}
          >
            <FaPlay  />
          </Button>
         
        </Box>
        </motion.div>
      </Box>
      </motion.image>
      {/*  */}
      {/*  */}
     
      <Modal open={openVideo} onClose={handleCloseVideo}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Centers the Box
            bgcolor: "background.paper",
            boxShadow: 24,
            width: { xs: "90%", sm: "80%", md: "60%", lg: "45%" }, // Responsive width
            height: { xs: "40%", md: "50%" }, // Height adapts for mobile
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "90%", // Prevents overflow on smaller screens
            overflowY: "auto",
          }}
        >
          <IconButton
            onClick={handleCloseVideo}
            sx={{ position: "absolute", top: 5, right: 5 }}
          >
            <FaTimes />
          </IconButton>

          {/* Responsive video player with ReactPlayer */}
          <Box
            sx={{
              width: "100%",
              position: "relative",
              paddingBottom: "56.25%",
            }}
          >
            <ReactPlayer
              url="https://www.youtube.com/watch?v=QPIlUSqcKrs"
              width="100%"
              height="100%"
              playing={false} // Set to true if autoplay is needed
              controls // Enables player controls
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Box>
        </Box>
      </Modal>
      <motion.div variants={textVariants}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "20px", md: "30px 20px" },
          // backgroundColor: "background.default",
          gap: { xs: 0, md: 8 },
        }}
      > 
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            textAlign: { xs: "center", md: "center" },
            mb: { xs: 4, md: 0 },
            p: 3,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              mb: 4,
              fontSize: { xs: "32px", md: "50px" },
              color: "#fff",
              fontFamily: "roboto, serif",
            }}
          >
            Why Choose Us
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{
              fontSize: { xs: "14px", md: "20px" },
              mb: 4,
              lineHeight: { xs: 1.5, md: 1.8 },
              color: "#AA816D",
            }}
          >
            Discover our exceptional services and commitment to your well-being.
            We offer personalized care that is second to none.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#381010",
              color: "#fff",
              borderTopLeftRadius: "30px",
              borderBottomRightRadius: "30px",
              px: "20px",
              py: "12px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#fff",
                color:"#381010"
              },
            }}
          >
            Explore More
          </Button>
        </Box>
       
        <Box
          id="book"
          sx={{
            width: { xs: "100%", sm: "60%" },
            height: { xs: "50%", sm: "100%" },
            mt: { xs: 0, sm: 0 },
             backgroundColor:"#28282B"
          }}
        />
      </Box>
          </motion.div>
      <Box
        sx={{
          // background: "#EDEBE4",
          // py: 3,
          // mt: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Marquee speed={100} gradient={false} style={{ marginTop: "10px" }}>
          {[
            "Hair Studio",
            "Nail Studio",
            "Beauty Studio",
            "Bridal Studio",
            "Spa center",
            "Tattoo Studio",
          ].map((text, index) => (
            <Typography
              key={index}
              variant="h1"
              fontWeight={"900"}
              sx={{
                position: "relative",
                color: "#AA816D",
                padding: "10px",
                marginLeft: "20px",
                display: "inline-block",
                 fontSize:{xs:"50px",md:"80px"},
                "&:hover": {
                  color: "#381010",
                 
                },
              }}
            >
              {text}
            </Typography>
          ))}
        </Marquee>
      </Box>
    </Box>
    </motion.section>
    </>
  );
}

export default WhyChooseUs;
