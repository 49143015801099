import { useState } from "react";
import { Box, Typography, TextField, Button, IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
 } from "@mui/material";
import bg2 from "../assets/touch-bg-2.svg";
import bg4 from "../assets/touch-bg-4.svg";
import { FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

function InTouch() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    msg: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappNumber = "+918943997999"; // Update with your number
    const { name, email, mobile, service, msg } = formData;
    if (!name || !mobile) {
      alert("Please fill in all required fields.");
      return;
    }

    const whatsappMessage = `Hello, I would like to book an appointment.\n\nName: ${name}\nEmail: ${email}\nMobile: ${mobile}\nService: ${service}\nMessage: ${msg}`;

    window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      whatsappMessage
    )}`;
  };



  return (
    <Box
      id="inTouchSection"
      sx={{
        position: "relative",
        py: { xs: 1, lg: 5 },
        px: { xs: 2, sm: 6, md: 8, lg: 24 },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
        backgroundColor: "#28282B",
      }}
    >
      {/* Main Container */}
      <Box
        sx={{
          width: { xs: "100%", lg: "90%" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          backgroundColor: "#381010",
          borderRadius: { xs: "20px", md: "0px" },
          position: "relative", // Required for stacking the images
          zIndex: 1, // Content in front of the images
        }}
      >
        {/* Form Section */}
        <Box
          sx={{
            backgroundColor: "#EDEBE4",
            flex: 3,
            p: { xs: 3, lg: 5 }, // Responsive padding
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#232323",
              fontSize: { xs: 30, lg: 47 },
              textAlign: "center",
            }}
          >
            Book <span style={{ color: "#2E4630" }}>Appointment</span>
          </Typography>
          <Typography sx={{ color: "#666", mt: 2, textAlign: "center" }}>
            Services that help our customers meet
          </Typography>

          {/* Form Inputs */}
          <Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              fullWidth
              placeholder="Name*"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              placeholder="Phone number*"
               variant="outlined"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
            <FormControl fullWidth variant="filled" >
              <InputLabel>Service</InputLabel>
              <Select
                name="service" // Add name attribute
                value={formData.service}
                onChange={handleChange}
                
              >
                <MenuItem value="Hair Studiog">Hair Studio</MenuItem> 
                <MenuItem value="Beauty Studio">Beauty Studio</MenuItem>
                <MenuItem value="Manicure & Pedicure">Manicure & Pedicure</MenuItem>
                <MenuItem value="Bridal Studio">Bridal Studio</MenuItem>
                <MenuItem value="Spa">Spa</MenuItem>
                <MenuItem value="Tattoo Studio">Tattoo Studio</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              placeholder="Message"
              variant="outlined"
           name="msg"
            multiline
            rows={4}
            value={formData.msg}
            onChange={handleChange}
            sx={{ borderBottom: "none",  }}
            required
            />
            <Button
              onClick={handleSubmit}
              sx={{
                bgcolor: "#381010",
                color: "#fff",
                borderRadius: "50px",
                mt: 2,
                p: "10px 20px",
                "&:hover": { boxShadow: 3 },
              }}
            >
              Book Now
            </Button>
          </Box>

          {/* Contact Info */}
          <Box sx={{ mt: 4, display: "flex", gap: 5, flexWrap: "wrap" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <IconButton>
                <FaPhone style={{ color: "#381010" }} />
              </IconButton>
              <Box>
                <Typography variant="body2" sx={{ color: "#381010" }}>
                  Phone
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                +91 8943997999
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <IconButton>
                <FaLocationDot color="#381010" />
              </IconButton>
              <Box>
                <Typography variant="body2" sx={{ color: "#381010" }}>
                  Location
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                Mavoor Rd, Kottooli, Kozhikode, Kerala 673016
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <IconButton>
                <MdEmail color="#381010" />
              </IconButton>
              <Box>
                <Typography variant="body2" sx={{ color: "#381010" }}>
                  Email
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                byjuspark@gmail.com
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Map Section */}
        <Box
          sx={{
            flex: 1,
            position: "relative",
            borderRadius: "0 20px 20px 0",
            minHeight: { xs: 300, sm: 400, md: 450, lg: 519 },
            width: { xs: "100%", sm: "100%", md: "100%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4653.402935842907!2d75.80408488856678!3d11.259214581876916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba6591f7b308d2d%3A0x1dcacf6134ca2a77!2sSpark%20International%20Beauty%20Salon%20%26%20Spa!5e0!3m2!1sen!2sin!4v1728439271547!5m2!1sen!2sin"
            style={{
              border: 0,
              minWidth: "350px",
              minHeight: "350px",
              height:"400px",
              width:"475px"
            }}
            loading="lazy"
          />
        </Box>
      </Box>

      {/* Additional background images */}
      <Box
        sx={{
          position: "absolute",
          bottom: -325,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          top: { xs: 200, lg: 400 },
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          component="img"
          src={bg2}
          alt="background2"
          sx={{
            opacity: 0.3,
            display: { xs: "none", lg: "block" },
          }}
        />
        <Box
          component="img"
          src={bg4}
          alt="background4"
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        />
      </Box>
    </Box>
  );
}

export default InTouch;