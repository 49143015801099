import React from 'react';
import { Box, IconButton } from '@mui/material';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr';
import { FaTimes } from 'react-icons/fa';

const PopupAlbum = ({ setIsPopupOpen, imsg, setImage, images }) => {
  const handleNextImage = () => {
    setImage(imsg === images.length - 1 ? 0 : imsg + 1);
  };

  const handlePrevImage = () => {
    setImage(imsg === 0 ? images.length - 1 : imsg - 1);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 50,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(31, 28, 20, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '90%', md: '70%', lg: '60%' },
          maxWidth: '800px',
          height: 'auto',
          maxHeight: '80vh',
          overflow: 'hidden',
        }}
      >
        {/* Close button in top right corner of the image */}
        <IconButton
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            color: 'white',
            fontSize: '2rem',
            zIndex: 11, // Ensure it's above the image
          }}
          onClick={() => setIsPopupOpen(false)}
        >
          <FaTimes />
        </IconButton>

        <IconButton
          sx={{
            position: 'absolute',
            left: 0,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
          }}
          onClick={handlePrevImage}
        >
          <GrFormPrevious />
        </IconButton>

        <img
          src={images[imsg].src}
          alt={images[imsg].alt}
          style={{ width: '100%', height: 'auto', borderRadius: '4px' }}
        />

        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
          }}
          onClick={handleNextImage}
        >
          <GrFormNext />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PopupAlbum;
