import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { FaInstagram, FaFacebook, FaWhatsapp, FaEnvelope, FaRegClock } from "react-icons/fa6";
import { motion, useAnimation, useInView, 
   
} from "framer-motion";
function AdditionalNavbar() {
  const whatsappNumber = "+918943997999";

  // 
  const gridContainerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("show");
    }
  }, [isInView, mainControls]);

  // 

  const socialIcons = [
    { icon: FaFacebook, href: 'https://www.facebook.com/profile.php?id=100089770780722' },
    { icon: FaInstagram, href: 'https://www.instagram.com/spark_international_salon/' },
    { icon: FaWhatsapp, href: `https://api.whatsapp.com/send?phone=${whatsappNumber}` },
  ];

  return (
    <>
    <motion.section
    ref={containerRef}
    variants={gridContainerVariants}
    initial="hidden"
    animate={mainControls}
    style={{backgroundColor:"#000"}}
  >
    <Box
      sx={{
        display: { xs: "none", lg: "flex" },
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#28282B",
        color: "white",
        px: 3,
        py: 1,
        fontFamily:"poppins"
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2,ml:1
       }}>
        <motion.div variants={textVariants}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FaRegClock />
          <Typography >Mon - Sunday, 08 am - 09 pm</Typography>
        </Box>
        </motion.div>
        <motion.div variants={textVariants}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1,ml:1 }}>
          <FaPhoneAlt />
          <Typography>+91 8943997999</Typography>
        </Box>
        </motion.div>
        <motion.div variants={textVariants}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, ml:1 }}>
          <FaEnvelope />
          <Typography >baijuspark@gmail.com</Typography>
        </Box>
        </motion.div>
      </Box>
       <motion.div variants={textVariants}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {socialIcons.map(({ icon: Icon, href }, idx) => (
          <IconButton
            key={idx}
            sx={{
              color: "white",
              bgcolor: "#381010",
              "&:hover": { bgcolor: "white", color: "#381010" },
              mx: 0.5,
            }}
            href={href}
          >
            <Icon size="1em" />
          </IconButton>
        ))}
      </Box>
      </motion.div>
    </Box>
    </motion.section>
    </>
  );
}

export default AdditionalNavbar;
