import React, { useEffect, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
// import lineBg from "../assets/line-bg.svg";
// import leaveBg1 from "../assets/hero-leave-bg.svg";
// import leaveBG2 from "../assets/leave-bg-2.svg";
import bg from "../assets/hero-.avif";
import serviceIcon1 from "../assets/make-up.png";
import serviceIcon2 from "../assets/hair-cutting.png";
import serviceIcon3 from "../assets/nail-artist.png";
import serviceIcon4 from "../assets/face-massager.png";
import background from "../assets/bg.avif";
import { Link } from "react-router-dom";
import { motion, useAnimation, useInView } from "framer-motion";
function Hero() {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true });
  const mainControls = useAnimation();
 

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  const imageVariants = {
    hidden: { opacity: 0, scale: 1.2 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeOut", delay: 0.5 },
    },
  };

  const serviceIcon = [
    { name: "Make Up Studio", img: serviceIcon1 },
    { name: "Hair Studio", img: serviceIcon2 },
    { name: "Nail Studio", img: serviceIcon3 },
    { name: "Spa & Massage", img: serviceIcon4 },
  ];

  return (
    <> <motion.section ref={containerRef} initial="hidden" animate={mainControls} style={{backgroundColor:"#343434"}}>
      <Box
        id="heroSection"
        sx={{
          position: "relative", // Ensures proper layering relative to navbar
          zIndex: 1, // Lower than navbar zIndex to avoid overlapping
          backgroundColor: "#343434",
          display: "flex",
          flexDirection: { xs: "column-reverse", lg: "row" },
          gap: { xs: "16px", lg: "10px", xl: "40px" },
          overflow: "hidden",
          pt: "30px",
          width: "100%",
          height: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <motion.image variants={imageVariants}>
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            width: "100%",
            justifyContent: "end",
          }}
        >
          {/* <Box
            component="img"
            src={leaveBg1}
            alt="_leave"
            sx={{
              mt: { xs: "-1700px", md: -30 },
              mb: { xs: "50px", md: "40px" },
              ml: { xs: "150px", md: "100px" },
              width: { xs: "100%", md: "auto" },
              maxWidth: { xs: "100%", md: "auto" },
            }}
          /> */}
        </Box>

        {/* <Box
          sx={{
            position: "absolute",
            display: { xs: "none", md: "flex" },
            height: "100%",
            justifyContent: "end",
            alignItems: "end",
          }}
        > */}
          {/* <Box
            component="img"
            style={{ marginTop: "478px", marginLeft: "700px" }}
            sx={{
              mt: { xs: "-1300px", md: -40 },
              ml: { xs: "150px", md: "100px" },
            }}
            src={leaveBG2}
            alt="_leave"
          /> */}
        {/* </Box> */}
            
        <Box
          component="img"
          src={bg}
          alt="Spark beauty parlour"
          sx={{
            zIndex: 1, // Ensures this image stays behind other content
            width: { xs: "100%", sm: "80%", md: "80%", lg: "80%" },
            height: { xs: "auto", md: "600px" },
            objectFit: "cover",
          
            ml:{xs:3,lg:10}
          }}
        />
        </motion.image>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: "10px",
            justifyContent: "center",
            alignItems: "start",
            // zIndex: 2, // Content stays on top
            // px: { xs: 4, sm: 6 },
            pr: { lg: "32px" },
            ml:{xs:2,md:2,lg:-25}
          }}
        >
          <motion.div variants={textVariants}>
          <Typography
            
            sx={{
              maxWidth: "626px",
              color: "#fff",
              fontSize: { xs: "35px", lg: "50px" },
              fontWeight: "600",
              fontFamily: "Abril Fatface, serif",
              mb:{xs:2,md:0},
            
            }}
          >
            Tranquil Haven Your Oasis for Beauty & Wellness
          </Typography>

          <Button
          component={Link}
            to={'/book'}
            
            sx={{
              backgroundColor: "#381010",
              color: "#fff",
              fontFamily: "Quicksand",
              fontSize: { xs: "12px", md: "16px" },
              height: { xs: "50px", md: "60px" },
              borderTopLeftRadius: "35px",
              borderBottomRightRadius: "35px",
              px: { xs: "16px", md: "26px" },
              py: "8px",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#381010",
                boxShadow: 2,
              },
            }}
          >
            Get Your Appointment
          </Button>
          </motion.div>
        </Box>
      </Box>
          <motion.div variants={textVariants} style={{backgroundColor:"#343434"}}>
      <Box
        sx={{
          backgroundColor: "#381010",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 4, lg: 24 },
          justifyContent: "center",
          px: { xs: 2, sm: 4, md: 12 },
          py: 6,
        }}
      >
        {serviceIcon.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={item.img} alt={`${item.name} icon`} />
            <Typography
            variant="h2"
              sx={{
                maxWidth: "150px",
                textAlign: "center",
                color: "white",
                fontFamily: "Mont, sans-serif",
                fontSize:"20px",
              }}
            >
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
      </motion.div>
      </motion.section>
    </>
  );
}

export default Hero;
