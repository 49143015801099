import React from 'react'
import Navbar1 from '../components/Navbar1'
import { Box, Typography } from '@mui/material'
import Title from '../components/Title';
import Footer from '../components/Footer';
import AdditionalNavbar from '../components/AdditionalNavbar'
function PrivacyPolicyPage() {
  return (
    <div>
      <AdditionalNavbar/>
      <Navbar1/>
      <Title heading={"Privacy Policy"} />

      <Box sx={{ mt:{xs:2,md:5} }} width={{ sx: "100%", md: "95%" }}>
        <Typography
          sx={{
            color: "#0E384C",
            fontWeight: "bold",
            fontSize: { xs: "1.6rem", md: "2.3rem" },
            ml: 1
          }}
        >
          Introduction
        </Typography>

        <Typography sx={{ mb: 1, p: 1,ml:1 }}>
          At Spark Beauty Parlor, Salon, and Spa, located in Kozhikode,
          we are committed to protecting the privacy and confidentiality of our
          clients. This Privacy Policy outlines the types of information we
          collect, how we use and protect that information, and your rights
          regarding your data. We adhere to strict privacy standards,
          particularly in compliance with Google and Meta (Facebook) advertising
          policies.
        </Typography>

        <Typography
          sx={{
            color: "#0E384C",
            fontWeight: "bold",
            fontSize: { xs: "1.6rem", md: "2.3rem" },
          }}
          ml={1}
        >
          Information We Collect
        </Typography>

        <Typography  sx={{ mb: 1, p: 1,ml:1 }}>
          To provide you with the best possible care and service, we may collect
          the following types of information:
        </Typography>
        <ul>
          <li>
            Personal Information: This includes your name, phone number, email
            address, and any other details necessary for communication and
            scheduling appointments.
          </li>
          <li>
            Communication Data: We may collect data related to the SMS messages
            and calls made to and from our spa, including the content of
            these communications, to ensure seamless interaction and follow-up
            with our clients.
          </li>
        </ul>

        <Typography
          sx={{
            color: "#0E384C",
            fontWeight: "bold",
            fontSize: { xs: "1.7rem", md: "2.3rem" },
          }}
          ml={1}
        >
          How We Use Your Information
        </Typography>

        <Typography  sx={{ mb: 1, p: 1,ml:1 }}>
          The information we collect is used solely for the purpose of enhancing
          your experience with Spark Beauty Parlor, Salon, and Spa. Specifically, we
          use your data to:
        </Typography>
        <ul>
          <li>
            Communicate with You: We use your contact information to send
            appointment reminders, respond to inquiries, and provide you with
            updates about our services.
          </li>
          <li>
            Service Improvement: We may use aggregated data to analyze and
            improve our services, ensuring that we meet the needs of our
            clients effectively.
          </li>
          <li>
            Marketing Compliance: As part of our advertising efforts with Google
            and Meta, we may use certain data to tailor our marketing
            strategies. However, this is done in compliance with their strict
            privacy guidelines.
          </li>
        </ul>

        <Typography
          sx={{
            color: "#0E384C",
            fontWeight: "bold",
            fontSize: { xs: "1.6rem", md: "2.3rem" },
          }}
          ml={1}
        >
          Data Storage and Security
        </Typography>

        <Typography  sx={{ mb: 1, p: 1,ml:1 }}>
          All data collected by Spark Beauty Parlor, Salon, and Spa is securely stored
          on our company’s computers and servers. We take extensive measures to
          protect your personal information from unauthorized access,
          disclosure, alteration, or destruction.
        </Typography>

        <Typography
          sx={{
            color: "#0E384C",
            fontWeight: "bold",
            fontSize: { xs: "1.6rem", md: "2.3rem" },
          }}
          ml={1}
        >
          Sharing of Information
        </Typography>

        <Typography  sx={{ mb: 1, p: 1,ml:1 }}>
          We respect your privacy and are committed to ensuring that your
          personal data is not shared with any third parties or outside
          organizations. We do not sell, trade, or otherwise transfer your
          information to any external entities for marketing or other purposes.
        </Typography>

        <Typography
          sx={{
            color: "#0E384C",
            fontWeight: "bold",
            fontSize: { xs: "1.6rem", md: "2.3rem" },
          }}
          ml={1}
        >
          Compliance with Advertising Standards
        </Typography>

        <Typography  sx={{ mb: 1, p: 1,ml:1 }}>
          Spark Beauty Parlor, Salon, and Spa complies with the advertising policies of
          Google and Meta. This includes ensuring that all data used in
          advertising campaigns is handled responsibly and in accordance with
          the privacy standards set forth by these platforms. We only use data
          for advertising purposes in ways that are consistent with their
          guidelines and respect your privacy.
        </Typography>

        <Typography
          sx={{
            color: "#0E384C",
            fontWeight: "bold",
            fontSize: { xs: "1.6rem", md: "2.3rem" },
          }}
          ml={1}
        >
          Your Rights
        </Typography>

        <Typography  sx={{ mb: 1, p: 1,ml:1 }}>
          You have the right to access, correct, or delete your personal
          information that we hold. If you have any concerns about your data or
          wish to exercise any of your rights, please contact us directly.
        </Typography>

        <Typography
          sx={{
            color: "#0E384C",
            fontWeight: "bold",
            fontSize: { xs: "1.6rem", md: "2.3rem" },
          }}
          ml={1}
        >
          Contact Us
        </Typography>

        <Typography  sx={{ mb: 1, p: 1,ml:1 }}>
          If you have any questions about this Privacy Policy or need further
          information, please feel free to contact us at:
        </Typography>

        <Box p={3}>
          <Typography variant="body1" sx={{ mb: 2, p: 2 }}>
            <strong>Spark Beauty Parlor, Salon, and Spa</strong>
            <br />
            Mavoor Road, Kottooli, Pottammal,
            <br/>
            Kozhikode, 673016, Kerala
            <br />
            Phone: +91 8943997999
          </Typography>
          {/* <Typography variant="body1" sx={{ mb: 2, p: 2 }}>
            <strong>Spark Beauty Parlor, Salon, and Spa - Naduvannur</strong>
            <br />
            Near Gramin Bank, Naduvannur, Kerala
            <br />
            Phone: +91 9961380622
          </Typography> */}
        </Box>
      </Box>
      <Footer/>
    </div>
  )
}

export default PrivacyPolicyPage
