import React from 'react'
import Navbar1 from '../components/Navbar1'
import Gallery from '../components/Gallery'
import Footer from '../components/Footer'
import Title from "../components/Title"
import AdditionalNavbar from '../components/AdditionalNavbar'
 function GalleryPage() {
  return (
    <div> 
      <AdditionalNavbar/>
        <Navbar1/>
        <Title heading={"Gallery"}/>
        <Gallery/>
        <Footer/>
    </div>
  )
}
export default GalleryPage;