import React, { useEffect, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import aboutImg from "../assets/about-img.svg";
import { Link } from "react-router-dom";
import { motion, useAnimation, useInView, 
  // useScroll, useTransform 
} from "framer-motion";
function About() {
  

  const gridContainerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true });
  const mainControls = useAnimation();

  

  useEffect(() => {
    if (isInView) {
      mainControls.start("show");
    }
  }, [isInView, mainControls]);

  return (
    <>
     <motion.section
        ref={containerRef}
        variants={gridContainerVariants}
        initial="hidden"
        animate={mainControls}
      >
      <Box
        id="aboutSection"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          gap: { xs: 5, md: 2, lg: 5 },
          justifyContent: "center",
          alignItems: "center",
          px: { xs: 4, sm: 6, md: 8, lg: 24 },
          // backgroundColor: "#DDDAD2",
          
          backgroundColor: "#28282B",
          position: "relative",
          overflow: "hidden",
        }}
      >
       
        {/* <Box
          sx={{
            position: "absolute",
            width: "96%",
            lg: { width: "100%" },
            display: "flex",
            justifyContent: "space-between",
            zIndex: 0,
          }}
        >
          <Box
            component="img"
            src={aboutBg1}
            alt="background 1"
            style={{
              
            }}
            sx={{
              // width: { xs: "50%", sm: "80%", md: "60%", lg: "200px" }, // Adjust the width based on screen size
              height: "200px", // Maintain the aspect ratio
              // objectFit: "cover",// Ensures the image covers the container properly
              mt:{xs:"1000px",md:"-1000px"},
              
              opacity: 0.5, 
            }}
          />
          <Box
            component="img"
            src={aboutBg2}
            alt="background 2"
            style={{ marginTop: "120px", display: { xs: "none", sm: "block" } }}
            sx={{
              width: { xs: "40%", sm: "80%", md: "60%", lg: "180px" }, // Adjust the width based on screen size
              height: "auto", // Maintain the aspect ratio
              objectFit: "cover", // Ensures the image covers the container properly
            }}
          />
        </Box> */}

        {/* Left Content Section */}
        <Box
          sx={{
            width: { lg: "50%", xl: "auto" },
            display: "flex",
            flexDirection: "column",
            gap: 3,
            pt: 8,
            zIndex: 1,
          }}
        >
          <motion.div variants={textVariants}>
          <Typography
            sx={{
              color: "#fff",
              fontFamily: "roboto, serif",
              fontSize: "24px",
              fontWeight: "500",
            }}
          >
            About Us
          </Typography>
          </motion.div>
          <motion.div variants={textVariants}>
          <Typography
            sx={{
              color: "#fff",
              fontFamily: "Abril Fatface, serif",
              fontSize: { xs: "40px", lg: "50px" },
              fontWeight: "500",
              lineHeight: 1.2,
              maxWidth: { xl: "537px" },
            }}
          >
            A Healthy Body Is A Guest-Chamber For The Soul
          </Typography>
          </motion.div>
          <motion.div variants={textVariants}>
          <Typography
            sx={{
              color:"#FAF9F6",

              fontFamily: "Mont, sans-serif",
              fontSize: "16px",
            }}
          >
            “Go On – Treat Yourself”
          </Typography>

          <Typography
            sx={{
              color: "#99816D",
              fontFamily: "Mont, sans-serif",
              fontSize: "15px",
              lineHeight: "20px",
              maxWidth: "507px",
              //   gap:"5px"
            }}
          >
            Beauty is finding confidence in your own skin, and we could not be
            more honored to help you along your journey. We’re a team of
            licensed professionals dedicated to making our clients feel safe,
            comfortable, and confident with the latest aesthetic treatments and
            procedures.
          </Typography>
         </motion.div>
         <motion.div variants={textVariants}>
          <Button
            component={Link}
            to={'/about'}
            sx={{
              display: { xs: "none", md: "flex" },
              backgroundColor: "#381010",
              color: "#fff",
              fontFamily: "Quicksand",
              fontSize: "16px",
              height: "60px",
              size: "large",
              maxWidth: "150px",
              borderTopLeftRadius: "35px",
              borderBottomRightRadius: "35px",
              px: "26px",
              py: "8px",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#28282B",
                boxShadow: 2,
              },
            }}
          >
            Read More
          </Button>
          </motion.div>
        </Box>

        {/* Right Image Section */}
        <Box
          sx={{
            width: { lg: "50%", xl: "auto" },
            zIndex: 1,
          }}
        >
           <motion.div   
           initial={{ scale: 1.1 }}
                animate={{ scale: 1 }}
                transition={{ duration: 2 }}
                >
          <Box
            component="img"
            src={aboutImg}
            alt="About us"
            sx={{
              width: { xs: "100%", sm: "80%", md: "60%", lg: "100%" }, // Adjust the width based on screen size
              height: "auto", // Maintain the aspect ratio
              objectFit: "cover", // Ensures the image covers the container properly
              paddingBottom: "96px", // Move padding here
            }}
          />
          </motion.div>
        </Box>
      </Box>
      </motion.section>
    </>
  );
}

export default About;
