import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

const commonTextFieldStyles = {
  mt: 2,
  backgroundColor: "#fff",
  "& .MuiFilledInput-underline:before": {
    borderBottomColor: "#fff",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
    borderBottomColor: "#fff",
  },
};

export default function BookAppointment() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    msg: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappNumber = "+918943997999"; // Update with your number
    const { name, email, mobile, service, msg } = formData;
    if (!name || !mobile) {
      alert("Please fill in all required fields.");
      return;
    }
  
    // Ensure message formatting is compatible across devices
    const whatsappMessage = `Hello, I would like to book an appointment.\n\nName: ${name}\nEmail: ${email}\nMobile: ${mobile}\nService: ${service}\nMessage: ${msg}`;
  
    // Use window.location.href for better compatibility with iOS
    window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      whatsappMessage
    )}`;
  };
  

  const handleClear = () => {
    setFormData({ name: "", email: "", mobile: "", service: "", msg: "" });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        color: "#fff",
        backgroundColor: "#28282B",
      }}
    >
      <Box sx={{ flex: 1, p: { xs: 2, md: 5 }, maxWidth: 900 }}>
        <Typography color="#8A6854" align="center" sx={{ mb: 3, fontSize:{xs:"1.4rem",md:"2.0rem"} }}>
          Book an Appointment
        </Typography>
        <Typography  sx={{ mb: 3, fontSize:{xs:"1.6rem",md:"2.5rem" }, textAlign:{xs:"left", md:"center"}, ml:{xs:1, md:0} }} >
          Please fill out the appointment form below
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            mt: 3,
          }}
        >
          <TextField
            label="Name"
            name="name"
            variant="filled"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            sx={commonTextFieldStyles}
            required
          />

          <TextField
            label="Email"
            name="email"
            variant="filled"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            sx={{ ...commonTextFieldStyles, ml: { xs: 0, md: 2 } }}
            required
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            mt: {xs:0,md:3},
          }}
        >
          <FormControl fullWidth variant="filled" sx={{ mt: 2 }}>
            <InputLabel>Service</InputLabel>
            <Select
              name="service"
              value={formData.service}
              onChange={handleChange}
              sx={{ backgroundColor: "#fff" }}
            >
              <MenuItem value="Hair Cutting">Hair Cutting</MenuItem>
              <MenuItem value="Spa">Spa</MenuItem>
              <MenuItem value="Makeup">Makeup</MenuItem>
              <MenuItem value="Manicure & Pedicure">
                Manicure & Pedicure
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Phone"
            name="mobile"
            variant="filled"
            fullWidth
            value={formData.mobile}
            onChange={handleChange}
            sx={{ ...commonTextFieldStyles, ml: { xs: 0, md: 2 } }}
            required
          />
        </Box>

        <TextField
          label="Message"
          name="msg"
          variant="filled"
          fullWidth
          multiline
          rows={4}
          value={formData.msg}
          onChange={handleChange}
          sx={{ mt: 3, backgroundColor: "#fff" }}
        />

        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#381010",
              mr: 2,
              "&:hover": { backgroundColor: "#fff", color: "#381010" },
            }}
            onClick={handleSubmit}
            size="large"
          >
            <Typography sx={{ fontSize: { xs: "14px", md: "18px" } }}>Book Appointment</Typography>
            
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#381010",
              "&:hover": { backgroundColor: "#fff", color: "#381010" },
              padding: { xs: "8px 16px", md: "12px 24px" }, // Adjust padding for responsive sizing
            }}
            onClick={handleClear}
          >
            <Typography sx={{ fontSize: { xs: "12px", md: "18px" } }}>
              Clear Form
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
