import React from 'react'
import Navbar1 from '../components/Navbar1'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Title from "../components/Title"
import AdditionalNavbar from '../components/AdditionalNavbar'
function ContactPage() {
  return (
    <div>
      <AdditionalNavbar/>
        <Navbar1/>
        <Title heading={"Contact Us"}/>
        <ContactUs/>
        <Footer/>
    </div>
  )
}

export default ContactPage