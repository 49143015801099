import React from 'react';
import { Box, Typography, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Typography variant="h1" color="error" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" gutterBottom>
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You may have mistyped the address or the page may have moved.
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleGoHome}>
          Go to Home Page
        </Button>
      </Box>
      <Box mt={2}>
        <Typography variant="body1">
          Or explore these helpful links:
        </Typography>
        <Box display="flex" justifyContent="center" mt={1}>
          <Link href="/" color="secondary" underline="hover" mx={2}>
            Home
          </Link>
          <Link href="/about" color="secondary" underline="hover" mx={2}>
            About Us
          </Link>
          <Link href="/contact" color="secondary" underline="hover" mx={2}>
            Contact
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
