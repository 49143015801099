// Importing the service images
import serviceImg1 from "../assets/services/hairsalon.avif";
import serviceImg2 from "../assets/services/nail-studio.avif";
import serviceImg3 from "../assets/services/face-treatment.avif";
import serviceImg4 from "../assets/makeup.jpeg";
import serviceImg5 from "../assets/services/bride.jpeg";
import serviceImg6 from "../assets/services/spa.avif";

// Exporting the service list array with detailed descriptions
export const serviceList1 = [
  {
    img: serviceImg1,
    heading: "Hair Studio",
    smallDescription: "Premium hair services for both gents and ladies, tailored to suit individual needs.",
    largeDescription: {
      serviceOverview: `
        At our Hair Studio, we take pride in delivering a full spectrum of premium hair care services, ensuring 
        that you leave with a look that not only reflects your personal style but also enhances your confidence.
        Our expert stylists use cutting-edge techniques and the finest products to give you the perfect cut, color, 
        or treatment. Whether you're coming in for a classic haircut, a modern hair color transformation, or 
        a luxurious hair treatment, we cater to your unique hair needs. We offer specialized services for both 
        gents and ladies, ensuring that each client gets the personalized care they deserve.
      `,
      faq: [
        {
          question: "Can I book an appointment for the same day?",
          answer: "Yes, though availability is subject to our schedule. We recommend booking in advance for weekends or holidays."
        },
        {
          question: "Do you offer hair treatments for damaged hair?",
          answer: "Absolutely, we offer various hair treatments, including keratin and smoothening, to repair and rejuvenate your hair."
        },
        {
          question: "What products do you use?",
          answer: "We only use high-quality, professional-grade products from trusted brands for all our services."
        }
      ],
      subServices: [
        "Ladies Haircut - Hair Trimming, Basic Cut, Layer Cut, Feather Cut, Creative Cut",
        "Gents Haircut - Normal Haircut, Creative Haircut", 
        "Beard - Normal, Creative Beard",
        "Hair Setting",
        "Hair Colour - Highlight, Global Colour, Global Highlight Ombre Colour",
        "Hair Colouring (Ammonia Free)- Crown , Root Touch-up, Global Colour",  
        "Hair Styling Treatment - Relaxing, Smoothening, Straightening, Rebonding, Keratin ",  
        "Hair Treatments - Hair Spa, Moisturising Hair Spa, Hair Fall Treatments, Power Mix Treatments, Colour Save Spa, Keratin Protection Spa, Repair Spa, Dandruff Treatments, Toxx Treatment)",
      ]
    },
    green: false,
  },
  {
    img: serviceImg2,
    heading: "Nail Studio",
    smallDescription: "Comprehensive nail care services for hands and feet, offering beautiful and healthy nails.",
    largeDescription: {
      serviceOverview: `
        Our Nail Studio is designed to provide the best nail care experience, from basic manicures and pedicures to 
        intricate nail art. Whether you're looking for a simple clean-up or a glamorous set of acrylics, our team 
        of nail technicians will pamper your hands and feet with great attention to detail. Using top-quality 
        products and the latest trends, we ensure your nails are healthy, polished, and perfectly styled. 
        The studio offers a range of services for all occasions, be it daily maintenance or a special event.
      `,
      faq: [
        {
          question: "Do you offer gel and acrylic nails?",
          answer: "Yes, we provide both gel and acrylic nail services, and we can help you choose the best option for your style and nail type."
        },
        {
          question: "Are your products safe for sensitive skin?",
          answer: "Yes, we use hypoallergenic and top-tier products to ensure safety for all skin types."
        },
        {
          question: "How long does a gel manicure last?",
          answer: "Gel manicures typically last for two to three weeks, depending on your daily activities and how well you care for your nails."
        }
      ],
      subServices: [
        "Nail Art - Gel Polish, Nail Extension, Poly Gel, Nail Design", 
        "Manicure - Nail Filing, Nail Polish, Polish & Filing, Normal Manicure, Spa Manicure", 
        "Pedicure - Nail Filing, Nail Polish, Polish & Filing, Normal Pedicure, Spa Pedicure"
      ]
    },
    green: false,
  },
  {
    img: serviceImg3,
    heading: "Beauty Studio",
    smallDescription: "Offering a wide variety of facial and skin treatments tailored to your needs.",
    largeDescription: {
      serviceOverview: `
        At our Beauty Studio, we focus on enhancing your natural beauty with personalized facial and skin treatments. 
        We understand that everyone's skin is different, and our skilled aestheticians are trained to assess your 
        skin type and recommend treatments that best suit your needs. From deep-cleansing facials that rejuvenate 
        your skin to anti-aging treatments that leave your face glowing, we use advanced skincare products and 
        techniques to deliver visible results. Whether you're prepping for a special occasion or simply indulging 
        in self-care, our Beauty Studio is the perfect place to pamper yourself.
      `,
      faq: [
        {
          question: "How do I choose the right facial?",
          answer: "Our beauty experts will analyze your skin and recommend the facial that best suits your skin type and concerns."
        },
        {
          question: "What products do you use for facials?",
          answer: "We use high-quality, dermatologist-approved products that are gentle on the skin and provide excellent results."
        },
        {
          question: "How often should I get a facial?",
          answer: "For best results, we recommend getting a facial every 4-6 weeks, depending on your skin's needs."
        }
      ],
      subServices: [
        "Fruit Facial", 
        "SensGlow Anti-tan Facial",
        "Anti-tan Facial",
        "Oxygen Facial", 
        "Pearl Facial",
        "Gold Facial",
        "Whitening Facial", 
        "Diamond Facial",
        "Platinium Facial",
        "Hydra Facial",
        "Threading - Upper Lip, Chin/Nose, Eyebrows, Face Side, Full Face",
        "Bleaching - Upper Lip, Underarms, Half Arms, Face & Neck, Full Arms, Full Back, Half Leg, Full Leg, Full body",
        "Waxing - Upper Lip, Chin, Side Face, Underarms, Belly Area, Half Arms, Full Back, Full Face, Half Leg, Full Arms, Full Leg, Full Arms, Full Leg, Bikini Line, Full Body",    
      ]
    },
    green: false,
  },
 
  {
    img: serviceImg5,
    heading: "Bridal Studio",
    smallDescription: "A variety of body treatments designed to rejuvenate your skin and relax your body.",
    largeDescription: {
      serviceOverview: `
        Our Body Care Studio offers a comprehensive range of treatments aimed at rejuvenating your body 
        and skin. From full-body exfoliation to specialized wraps, we ensure that you not only look good 
        but feel completely refreshed. Using high-quality, nourishing products, we help you achieve soft, 
        glowing skin while relieving stress. Whether it's a detox treatment, a slimming body wrap, or 
        a moisturizing body scrub, you'll leave feeling relaxed and renewed.
      `,
      faq: [
        
        {
          question: "What bridal services do you offer?",
          answer: "We offer a complete range of bridal services, including makeup, hairstyling, saree or gown draping, and pre-wedding skincare treatments to ensure you look flawless on your special day."
        },
        {
          question: "Do you offer bridal makeup packages?",
         answer: "Yes, we offer comprehensive bridal makeup packages that include trial sessions, hair styling, and full-day services."
       },
        {
          question: "Do you provide pre-bridal packages?",
          answer: "Yes, our pre-bridal packages include facials, body polishing, hair treatments, and skin care to prepare you for your big day with glowing, flawless skin"
        }
      ],
      subServices: [
        "Saree Draping", 
        "Bridal Hairdo", 
        "Mehandi", 
        "Normal Makeup", 
        "Bridal Package - Gold Bridal Package, Diamond Bridal Studio, Platinium Bridal Package"
      ]
    },
    green: false,
  },
  {
    img: serviceImg6,
    heading: "Spa Center",
    smallDescription: "Relaxing spa and massage therapies to rejuvenate your mind and body.",
    largeDescription: {
      serviceOverview: `
        Our Spa and Massage services offer a sanctuary where you can unwind and let go of the stresses of daily life. 
        With a variety of treatments designed to relax and refresh, we focus on both physical and mental well-being. 
        Our experienced therapists specialize in different massage techniques, including Swedish, deep tissue, and 
        hot stone massages, tailored to your needs. Whether you need to release muscle tension, improve circulation, 
        or simply indulge in a luxurious spa experience, we provide a calming environment where you can rejuvenate 
        and recharge.
      `,
      faq: [
        {
          question: "Do you offer couples massages?",
          answer: "Yes, we have special packages for couples who want to enjoy a massage experience together."
        },
        {
          question: "How long do the massage sessions last?",
          answer: "We offer various durations, including 30, 60, and 90-minute sessions to fit your schedule."
        },
        {
          question: "Can I request a specific therapist?",
          answer: "Yes, you can request a specific therapist based on your preferences."
        }
      ],
      subServices: [
        "Head Massage",
        "Face Massage",
        "Foot Massage",
        "Swedish Massage", 
        "Neck & Back Massage", 
        "Triditional Thai Massage",
        "Aroma Massage",
        "Deep Tissue Massage",
        "Balinese Massage",
        "Normal Body Polishing",
        "Ageless Spa",
      ]
    },
    green: false,
  },

  {
    img: serviceImg4,
    heading: "Tattoo Studio",
    smallDescription: "Expert tattoo services with a wide range of styles and designs to bring your ideas to life.",
    largeDescription: {
      serviceOverview: `
        Our Tattoo Studio offers professional tattooing services, specializing in custom designs and a variety of styles 
        including traditional, black-and-grey, and realism. Our experienced tattoo artists work with you to create 
        meaningful artwork tailored to your vision. We prioritize hygiene and use high-quality, sterilized equipment 
        to ensure a safe and satisfying experience. Whether it's your first tattoo or you're adding to an existing collection, 
        our team is dedicated to delivering top-notch artistry.
      `,
      faq: [
        {
          question: "Do you offer custom tattoo designs?",
          answer: "Yes, our artists can work with you to create a custom design that aligns with your vision and style preferences."
        },
        {
          question: "How do you ensure a safe and hygienic experience?",
          answer: "We use sterilized, single-use equipment and follow strict hygiene protocols to ensure your safety."
        },
        {
          question: "Can I book a consultation before getting a tattoo?",
          answer: "Absolutely, we encourage consultations to discuss design, placement, and any questions you may have."
        }
      ],
      subServices: [
        "Custom Designs",
        "Cover-Ups",
        "Black-and-Grey",
        "Realism"
      ]
    },
    green: false,
  }
  
  // 
];
