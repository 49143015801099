import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
} from "@mui/material";
import { FiChevronDown, FiPhone } from "react-icons/fi";
import  {serviceList1}  from "../data/service";
import { useParams } from "react-router-dom";
import Navbar1 from "../components/Navbar1";
import Footer from "../components/Footer";
import Title from "../components/Title";
import { Helmet } from "react-helmet-async";
import AdditionalNavbar from "../components/AdditionalNavbar";
import { motion, useAnimation, useInView } from "framer-motion";

function ServiceDetails() {
  const { heading } = useParams(); // Get the service ID from the URL
  const [expanded, setExpanded] = useState(false);
  const [selectedService, setSelectedService] = useState(serviceList1[0]);

    // Animation
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: true });
    const controls = useAnimation();
  
    useEffect(() => {
      if (isInView) {
        controls.start("visible");
      }
    }, [isInView, controls]);
  
    const imageVariants = {
      hidden: { opacity: 0, scale: 1.2 },
      visible: {
        opacity: 1,
        scale: 1,
        transition: { duration: 1, ease: "easeOut" },
      },
    };
  
    const gridVariants = {
      hidden: { opacity: 0, y: 50 },
      visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 1, ease: "easeOut", staggerChildren: 0.2 },
      },
    };
  
    const buttonHover = {
      whileHover: { scale: 1.05, transition: { duration: 0.3 } },
    };
  

  // Use useEffect to set the selected service based on the ID
  useEffect(() => {
    const service = serviceList1.find((service) => 
      service.heading.toLowerCase().replace(/\s+/g, "-") === heading
    );
    if (service) {
      setSelectedService(service);
    }
  }, [heading]);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <><motion.section
    ref={containerRef}
    initial="hidden"
    animate={controls}
    variants={gridVariants}
    style={{ backgroundColor: "#343434" }}
  >
    <Box backgroundColor="#28282B">
       <Helmet>
      <title> {selectedService.heading}</title>
      <meta name="description" content="Byju Spark International Salon & Beauty Mall in Calicut offers premium beauty and salon services. Experience top-quality care in a luxurious setting" />
      <link rel="canonical" href="https://www.byjuspark.com/" />
      <meta property="og:title" content="Byju Spark International Salon & Beauty Mall Calicut" />
      <meta property="og:description" content="Discover the Top beauty Parlour and salon services at Byju Spark International Salon & Beauty Mall, located in Calicut." />
      <meta property="og:url" content="https://www.byjuspark.com/" />
      <meta property="og:type" content="website" />
    </Helmet>
    <AdditionalNavbar/>
      <Navbar1 />
     
      <Title heading={selectedService.heading} style={{marginTop:1}}/>
      {/* Main Content */}
      <Grid container sx={{ px: { xs: 2, md: 8 }, py: 4 }}>
        {/* Left Side: Service Headings & Contact */}
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Service Headings */}
          <motion.div variants={gridVariants} style={{width:"100%",}}>
          <Box sx={{ width: "100%", mb: 4, mr: { xs: 0, md: 5 } }}>
            {serviceList1.map((service, index) => (
               <motion.div
               key={index}
               whileHover={buttonHover.whileHover}
             >
              <Button
                key={index}
                fullWidth
                sx={{
                  textAlign: "left",
                  mb: 1,
                  fontWeight: "bold",
                  backgroundColor: "#381010",
                  height: "75px",
                  color: selectedService === service ? "#AA816D" : "#fff",
                  borderColor: "#AA816D",
                  "&:hover": { backgroundColor: "#EDEBE4" },
                }}
                onClick={() => setSelectedService(serviceList1[index])}
              >
                {service.heading}
              </Button>
              </motion.div>
            ))}
          </Box>

          {/* Contact Section */}
          <Box
            sx={{
              backgroundColor: "#381010",
              p: 2,
              borderRadius: 2,
              textAlign: "center",
              width: "100%",
              height: { xs: "auto", md: "400px" },
              mr: { xs: 0, md: 5 },
              mb: 5,
              color: "#fff"
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                mt: 2,
                mb: 2,
                fontFamily: "roboto",
                lineHeight: 1.5,
              }}
            >
              Contact with
              <br /> us for any <br /> advice
            </Typography>
            <FiPhone size={40} style={{ color: "#FAF9F6" }} />
            <Typography sx={{ mt: 1 }}>Need help? Talk to an expert</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
            +91 8943997999
            </Typography>
          </Box>
          </motion.div>
        </Grid>

        {/* Right Side: Service Details */}
        <Grid item xs={12} md={9}>
          <Box sx={{ ml:{xs:0,md:5},}}>
            {/* Service Image */}
            <motion.img
                  src={selectedService.img}
                  alt={selectedService.heading}
                  initial="hidden"
                  animate="visible"
                  variants={imageVariants}
                  style={{
                    width: "100%",
                    height: "auto",
                    marginBottom: "3rem",
                    padding:3
                  }}
                />
            {/* Service Heading */}
            <Typography
              variant="h1"
              color="#fff"
              sx={{ mb: 2, fontWeight: "bold" , fontSize:{xs:"35px",md:"50px"}}}
            >
              {selectedService.heading}
            </Typography>

            {/* Service Overview */}
            <Typography
              variant="body1"
              sx={{ mb: 4, lineHeight: 2, fontSize: "18px", color: "#AA816D" }}
            >
              {selectedService.largeDescription.serviceOverview}
            </Typography>

            {/* Sub-Services Section */}
            <Typography
              variant="h4"
              color="#fff"
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              Services Offered
            </Typography>
            <ul
              style={{ margin: 0, paddingLeft: "1rem", listStyleType: "disc" }}
            >
              {selectedService.largeDescription.subServices.map(
                (item, index) => (
                  <li key={index}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#AA816D",
                      }}
                    >
                      {item}
                    </Typography>
                  </li>
                )
              )}
            </ul>

            {/* FAQ Section */}
            {Array.isArray(selectedService.largeDescription.faq) &&
              selectedService.largeDescription.faq.length > 0 && (
                <>
                  <Typography
                    variant="h5"
                    color="#fff"
                    sx={{ mt: 6, mb: 2, fontWeight: "bold" }}
                  >
                    Frequently Asked Questions
                  </Typography>
                  {selectedService.largeDescription.faq.map(
                    (faqItem, index) => (
                      <Accordion
                        key={index}
                        expanded={expanded === index}
                        onChange={handleAccordionChange(index)}
                      >
                        <AccordionSummary expandIcon={<FiChevronDown />}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "600" }}
                          >
                            {faqItem.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body2" sx={{ color: "gray" }}>
                            {faqItem.answer}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )
                  )}
                </>
              )}
          </Box>
        </Grid>
      </Grid>

      <Footer />
    </Box>
    </motion.section>
    </>
  );
}

export default ServiceDetails;
