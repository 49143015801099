import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopupAlbum from "./PopupAlbum";
import { Box, Typography, Grid } from "@mui/material"; // Use the new Grid from MUI v5+
import makeup from "../assets/gallery/hero-bg.avif"
import bodyCare from "../assets/gallery/woman-cosmetologist-.avif"
import spark1 from "../assets/gallery/spark.jpg"
import spark2 from "../assets/gallery/spark1.jpg"
import spark3 from "../assets/gallery/spark2.jpg"
import spark4 from "../assets/gallery/spark3.jpg"

const images = [
  {
    src: spark1,
    alt: "Spark International Beauty Salon & Spa",
  },
  {
    src: spark2,
    alt: "Makeup",
  },
  {
    src: spark3,
    alt: "Sand makeup",
  },
  {
    src: spark4,
    alt: "Makeup",
  },
  {
    src: makeup,
    alt: "Spark Makeup Studio",
  },
  {
    src: bodyCare,
    alt: "Spark Beauty Studio",
  },
 
];


const Gallery = () => {
  const [isPopOpen, setIsPopupOpen] = useState(false);
  const [imsg, setImage] = useState(0);

  const openPopup = (index) => {
    setIsPopupOpen(true);
    setImage(index);
  };

  return (
    <Box sx={{ backgroundColor: "#28282B", py: 5 }}>
      {/* Title Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: 1,
          color: "#fff",
          mt: 3, // Optional margin-top for spacing from other sections
          p:2
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontFamily: "Quicksand", fontWeight: "600" }}
        >
          Gallery
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Abril Fatface",
            mt: 1,
            maxWidth: "679px",
            textAlign: "center",
          }}
        >
          Step into a world of beauty and relaxation
        </Typography>
        <Typography
          variant="body1"
          color="#AA816D"
          sx={{
            mt: 3,
            maxWidth: "743px",
            fontFamily: "roboto, serif",
            textAlign: "center",
          }}
        >
          Explore our gallery showcasing the beauty and relaxation experiences we offer at our beauty mall.
        </Typography>
      </Box>

      <Box width="80%" mx="auto" pb={10}>
        {/* Mobile Slider */}
        <Box pt={2} display={{ xs: "block", md: "none" }}>
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={3000}
            centerMode={true} // Center images in the slider
            centerPadding="0px" // Remove extra padding on the sides
          >
            {images.map((image, index) => (
              <Box display="flex" justifyContent="center" key={index}>
                <Box
                  component="img"
                  src={image.src}
                  alt={image.alt}
                  sx={{
                    width: { xs: "90%", sm: "80%" },
                    height: "auto",
                    transition: "transform 0.3s",
                    "&:hover": { transform: "scale(1.1)" },
                    borderRadius: "12px",
                  }}
                  onClick={() => openPopup(index)}
                />
              </Box>
            ))}
          </Slider>
        </Box>

        {/* Desktop Grid */}
        <Grid
          container
          justifyContent="center"
          spacing={4} // Spacing between grid items
          mt={6}
          display={{ xs: "none", md: "flex" }}
        >
          {images.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                component="img"
                src={image.src}
                alt={image.alt}
                sx={{
                  width: "100%",
                  height: "auto",
                  transition: "transform 0.3s",
                  "&:hover": { transform: "scale(1.1)" },
                  borderRadius: "12px",
                }}
                onClick={() => openPopup(index)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {isPopOpen && (
        <PopupAlbum
          setIsPopupOpen={setIsPopupOpen}
          imsg={imsg}
          setImage={setImage}
          images={images}
        />
      )}
    </Box>
  );
};

export default Gallery;
