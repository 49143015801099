// index.js
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import CssBaseline from '@mui/material/CssBaseline';
import { HelmetProvider } from 'react-helmet-async';
import "./index.css"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <HelmetProvider>
     {/* <ThemeProvider theme={theme}> */}
    <CssBaseline /> {/* This applies baseline styles like reset.css */}
  <App />
  {/* </ThemeProvider> */}
  </HelmetProvider>
</BrowserRouter>,
);




// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// // import reportWebVitals from './reportWebVitals';
// import { ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
// import theme from './theme'; // Import your custom theme
// import { BrowserRouter } from 'react-router-dom';
// // import { HelmetProvider } from 'react-helmet-async';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <BrowserRouter>
//   {/* <HelmetProvider> */}
//      <ThemeProvider theme={theme}>
//     <CssBaseline /> {/* This applies baseline styles like reset.css */}
//   <App />
//   </ThemeProvider>
//   {/* </HelmetProvider> */}
// </BrowserRouter>,
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
