import React from 'react'
import Navbar1 from '../components/Navbar1'
import BookAppointment from '../components/BookAppointment'
import Footer from '../components/Footer'
import AdditionalNavbar from '../components/AdditionalNavbar';

function AppointmentPage() {
  return (
    <div>
      <AdditionalNavbar/>
        <Navbar1/>
        <BookAppointment/>
        <Footer/>
    </div>
  )
}

export default AppointmentPage