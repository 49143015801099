import { Box, Typography, Button, Grid2 } from "@mui/material";
import { serviceList1 } from "../data/service";
import {  useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Services() {
  const navigate = useNavigate();

  const handleClick = (service) => {
    if (!service.heading) {
      console.error("Service heading is undefined or missing");
      return;
    }

    const formattedId = service.heading.toLowerCase().replace(/\s+/g, "-");
    navigate(`/service/${formattedId}`, {
      state: { service }, // Passing the full service data via state
    });
  };

  return (
    <Box id="servicesSection" sx={{ position: 'relative', backgroundColor: '#28282B' }}>
      <Box
        sx={{
          pt: 10,
          px: { xs: 4, sm: 6, md: 8, lg: 16 },
          display: "flex",
          flexDirection: "column",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Title Section */}
        <Box sx={{ textAlign: "center", zIndex: 1, color: "#fff" }}>
          <Typography variant="h5" sx={{ fontFamily: 'Quicksand', fontWeight: "600" }}>
            Services
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontFamily: 'Abril Fatface', mt: 1, maxWidth: '679px' }}
          >
            Our Beauty Services
          </Typography>
          <Typography
            variant="body1"
            color="#AA816D"
            sx={{ mt: 3, maxWidth: '743px', fontFamily: "roboto, serif" }}
          >
            Enjoy our premium beauty and wellness treatments, from makeup and hairstyling to spa and massage services.
            Our experts ensure a personalized experience, leaving you refreshed and renewed.
          </Typography>
        </Box>

        {/* Service Lists */}
        <Grid2 container spacing={4} justifyContent="center" alignItems="center" sx={{ textAlign: { xs: "center", md: "left" }, mx: "auto" }}>
          {serviceList1.map((item, index) => (
            <Grid2
              item
              xs={10}
              md={4}
              key={index}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              {/* Framer Motion for smooth transitions */}
              <motion.div
                whileHover={{ scale: 1.05 }} // Slight scaling effect on hover
                initial={{ opacity: 0, scale: 0.9 }} // Initial animation state
                animate={{ opacity: 1, scale: 1 }} // Final animation state
                transition={{ duration: 0.5 }} // Animation timing
                style={{ textAlign: "center", width: '100%' }} // Ensuring it doesn't break layout
              >
                <Box
                  component="img"
                  src={item.img}
                  alt="_service"
                  sx={{
                    width: { xs: "300px", md: "350px" },
                    height: { xs: "250px", md: "300px" },
                    borderRadius: 1,
                  }}
                />
                <Box
                  sx={{
                    mt: { xs: -7, md: -10 },
                    mx: "auto",
                    width: { xs: "260px", md: "300px" },
                    p: 2,
                    borderRadius: 2,
                    textAlign: "center",
                    backgroundColor: item.green ? "#2E4630" : "white", // Background color based on condition
                    position: "relative", // For positioning background color at the bottom
                    minHeight: { xs: "140px", md: "180px" }, // Adjust height for content
                    boxShadow: 3, // Optional: shadow for visual separation
                  }}
                >
                  <Typography
                    variant="h6"
                    color={"#737372"}
                    sx={{ fontFamily: 'Quicksand' }}
                  >
                    {item.heading}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#381010"
                    sx={{ mt: 1, fontFamily: "roboto, serif" }}
                  >
                    {item.smallDescription}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                  <Button
                    onClick={() => handleClick(item)} // Pass the full item here
                    sx={{
                      mt: -4,
                      color: item.green ? "#2E4630" : "white",
                      backgroundColor: item.green ? "white" : "#381010",
                      fontWeight: "bold",
                      borderTopLeftRadius: "30px",
                      borderBottomRightRadius: "30px",
                      px: 3,
                      py: 1.5,
                      "&:hover": { boxShadow: 4 },
                    }}
                  >
                    Read More
                  </Button>
                </Box>
              </motion.div>
            </Grid2>
          ))}
        </Grid2>

      </Box>
    </Box>
  );
}

export default Services;
